// 変数の設定

//fonts
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$gothic: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$dir: "/wp/wp-content/themes/remax-apex/shared/img";

$base-color: #2A4E86;
$text-black: #202124;
$text-red: #E60012;

$main-color: #FDC54F;
$border-color: #deddde;

$link-color: #4C6CB4;

$required-color: #FF0000;

$min-padding: 15px;