
body{
	@media (max-width: 991.98px){
		font-size: 1.4em;
	}
	@media (max-width: 767.98px){
		font-size: 1.2em;
	}
}

.small_block{
	display: none;
	@media (max-width: 767.98px){
		display: block;
	}
}
.xsmall_block{
	display: none;
	@media (max-width: 575.98px){
		display: block;
	}
}

#header{
	background-color: #25457E;
	.h_inner{
		display: flex;
		flex-wrap : wrap;
		justify-content: space-between;
		align-items: center;
		.h_nav{
			ul{
				display: flex;
				flex-wrap : wrap;
				justify-content: flex-end;
				align-items: center;
				margin: 0px;
				padding: 0px;
				list-style-type: none;
				li{
					color: #FFFFFF;
					font-size: 0.75em;
					font-weight: bold;
					&:not(:last-child){
						padding-right: calc( 100vw * 0.07 );
					}
					a{
						display: block;
						padding: 8px;
						position: relative;
						&:after{
							content: "";
							display: inline-block;
							width: 30px;
							height: 1px;
							background-color: rgba(255,255,255,0.8);
							position: absolute;
							left: 50%;
							bottom: 0px;
							transform: translate3d(-50%,0,0);
							opacity: 0;
							transition: opacity 0.3s ease;
						}
						&:hover{
							&:after{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		@media (max-width: 991.98px){
			display: block;
			text-align: center;
			.h_nav{
				ul{
					justify-content: center;
					padding-bottom: 15px;
				}
			}

		}
		@media (max-width: 767.98px){
			.h_nav{
				ul{
					display: block;
					text-align: center;
					li{
						&:not(:last-child){
							padding-right: 0px;
						}
						a{
							&:after{
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}

#visual{
	position: relative;
	width: 100%;
	height: calc( 100vw * 0.689 );
	min-height: 520px;
	background: url("../images/visual.jpg") center top no-repeat;
	background-size: cover;
	@media only screen and (-webkit-min-device-pixel-ratio: 2){
		background-image:  url("../images/visual@2x.jpg");
	}
	.text_wrap{
		position: absolute;
		left: 0px;
		right: 0px;
		top: 6.3%;
		text-align: center;
		color: #FFFFFF;
		line-height: 1;
		text-align: center;
		@media (max-width: 575.98px){
			font-size: 0.8em;
		}
		.visual_logo{
			margin-bottom: 20px;
		}
		.text01{
			font-size: 2em;
			font-weight: 500;
			margin-bottom: 10px;
		}
		.text02{
			font-size: 2.8em;
			font-weight: bold;
			margin-bottom: 20px;
		}
		.text03{
			font-size: 1.5em;
		}
	}
}

.f_mincho{
     font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
}
.f_en{
	font-family: Arial, Helvetica, "sans-serif";
}
.f_large{
	font-size: 110%;
}
.f_xlarge{
	font-size: 120%;
}
.f_xxlarge{
	font-size: 130%;
}
.f_small{
	font-size: 90%;
}
.f_xsmall{
	font-size: 80%;
}
.f_xxsmall{
	font-size: 70%;
}

#overview{
	.block01{
		padding-top: 100px;
		padding-bottom: 100px;
		@media (max-width: 767.98px){
			padding-top: 80px;
			padding-bottom: 80px;
		}
		@media (max-width: 575.98px){
			padding-top: 60px;
			padding-bottom: 60px;
		}
		.subtitle{
			text-align: center;
			font-size: 2em;
			font-weight: normal;
			margin-bottom: 10px;
			@media (max-width: 575.98px){
				font-size: 1.6em;
			}
		}
		.description{
			text-align: center;
			font-size: 1.4em;
			margin-bottom: 60px;
			@media (max-width: 575.98px){
				font-size: 1.3em;
				margin-bottom: 40px;
			}
		}
		.title{
			font-size: 1.25em;
			margin-bottom: 20px;
		}
		.overview_table{
			width: 100%;
			th{
				width: 10em;
				text-align: left;
				font-weight: normal;
				padding: 10px 0px;
				vertical-align: top;
			}
			td{
				padding: 10px 0px;
				vertical-align: top;
			}
			@media (max-width: 575.98px){
				tbody,tr,th,td{
					display: block;
					width: 100%;
				}
				th{
					padding: 0px;
					font-weight: bold;
				}
				td{
					padding: 0px;
					margin-bottom: 10px;
				}
			}
		}
	}
	.gallery{
		width: 100%;
		height: calc( 100vw * 0.377 );
		//min-height: 500px;
		background: url("../images/image_gallery.jpg") center top no-repeat;
		background-size: cover;
		@media only screen and (-webkit-min-device-pixel-ratio: 2){
			background-image:  url("../images/image_gallery@2x.jpg");
		}
	}
	.block02{
		position: relative;
		width: 100%;
		height: calc( 100vw * 0.462 );
		min-height: 500px;
		background: url("../images/image04.jpg") center top no-repeat;
		background-size: cover;
		@media only screen and (-webkit-min-device-pixel-ratio: 2){
			background-image:  url("../images/image04@2x.jpg");
		}
		.text_wrap{
			width: 633px;
			max-width: 100%;
			min-width: 50%;
			height: 100%;
			background: -moz-linear-gradient(top,  rgba(13,26,48,0.88) 0%, rgba(87,102,129,0.75) 55%, rgba(87,102,129,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(13,26,48,0.88) 0%,rgba(87,102,129,0.75) 55%,rgba(87,102,129,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(13,26,48,0.88) 0%,rgba(87,102,129,0.75) 55%,rgba(87,102,129,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e00d1a30', endColorstr='#00576681',GradientType=0 ); /* IE6-9 */
			color: #FFFFFF;
			text-align: center;
			@media (max-width: 991.98px){
				width: 100%;
			}
			.text_inner{
				display: inline-block;
				text-align: left;
				padding-top: 80px;
				@media (max-width: 767.98px){
					padding-top: 70px;
				}
				@media (max-width: 575.98px){
					padding-top: 60px;
				}
			}
			.text01{
				margin-bottom: 10px;
				font-weight: bold;
			}
			.text02{
				margin-bottom: 30px;
			}
			.text03{
				line-height: 2;
				font-weight: bold;
			}
		}
	}
}

#resort{
	.visual{
		position: relative;
		width: 100%;
		height: calc( 100vw * 0.661 );
		min-height: 460px;
		background: url("../images/image05.jpg") center top no-repeat;
		background-size: cover;
		@media only screen and (-webkit-min-device-pixel-ratio: 2){
			background-image:  url("../images/image05@2x.jpg");
		}
	}
	.resort_logo{
		padding-top: 15.8%;
		text-align: center;
		@media (max-width: 767.98px){
			padding-left: 10%;
			padding-right: 10%;
		}
	}
	.text_wrap{
		text-align: center;
		flex: 1;
		.subtitle{
			font-size: 3em;
			font-weight: normal;
			letter-spacing: 0.02em;
			margin-bottom: 10px;
			@media (max-width: 767.98px){
				font-size: 2em;
				margin-bottom: 0px;
			}
		}
		.description{
			line-height: 1.8;
			@media (max-width: 767.98px){
				font-size: 0.8em;
				line-height: 1.5;
			}
		}
	}
	.block{
		align-items: center;
		.thumb{
			width: 50%;
			height: 0px;
			padding-bottom: 35%;
			background: url("../images/image06.jpg") center top no-repeat;
			background-size: cover;
			@media only screen and (-webkit-min-device-pixel-ratio: 2){
				background-image:  url("../images/image06@2x.jpg");
			}
			@media (max-width: 767.98px){
				padding-bottom: 45%;
			}
		}
	}
	.block:nth-child(2){
		background-color: #222222;
		color: #FFFFFF;
		.thumb{
			order: 2;
			background: url("../images/image7.jpg") center top no-repeat;
			background-size: cover;
			@media only screen and (-webkit-min-device-pixel-ratio: 2){
				background-image:  url("../images/image07@2x.jpg");
			}
		}
	}
}


#network{
	background-color: #F6F6F6;
	padding-top: 100px;
	padding-bottom: 100px;
	@media (max-width: 767.98px){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@media (max-width: 575.98px){
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.subtitle{
		text-align: center;
		margin-bottom: 60px;
		@media (max-width: 767.98px){
			margin-bottom: 50px;
		}
		@media (max-width: 575.98px){
			margin-bottom: 40px;
		}
	}
	.area{
		.title{
			margin-bottom: 10px;
		}
		.box{
			li{
				margin-bottom: 10px;
				color: #174C88;
			}
			margin-bottom: 20px;
		}
	}
}

#footer{
	background-color: #4A4A4A;
	color: #FFFFFF;
	.f_inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.copyright{
			font-size: 0.75em;
			padding: 15px;
		}
		.contact{
			order: 2;
		}
		.btn_contact{
			display: inline-flex;
			width: 270px;
			max-width: 100%;
			height: 62px;
			justify-content: center;
			align-items: center;
			background-color: #004353;
			color: #FFFFFF;
			font-weight: bold;
		}
		@media (max-width: 575.98px){
			display: block;
			.copyright{
				text-align: center;
			}
			.contact{
				order: 1;
			}
			.btn_contact{
				width: 100%;
			}
		}
	}
}

.totop{
	text-align: center;
	a{
		display: inline-block;
		padding: 20px;
	}
}






