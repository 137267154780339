// mixinsの読み込み
@import "./mixins/include.scss";
@import "_variables.scss";

/******************************************
* ie11のみ適応
******************************************/
@mixin ie11() {
    @media all and (-ms-high-contrast:none){
        @content;
    }
}

/******************************************
* reset
******************************************/
* {
    box-sizing: border-box;
    margin: 0;
    -webkit-font-smoothing: antialiased;
}
a {
    color: inherit;
    text-decoration: none;
    &.a-deco {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
br.wbr {
    @include sp {
        display: none;
    }
}
img {
    max-width: 100%;
    height: auto;
    border: none;
	vertical-align: middle;
    &.fit-cover {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
}
.fit-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

table {
    border-collapse: collapse;
}
address {
    font-style: normal;
}

html {
    scroll-behavior: smooth;
    font-size:62.5%;
    height: 100%;
    &.is-locked {
        @include md {
            overflow: hidden;
        }
    }
}
body {
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-family: $gothic;
    font-size: 1.6em;
    overflow-x: hidden;
}
.screen-reader-text{
    display: none;
}

/******************************************
* utility
******************************************/

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.list-reset {
    list-style: none;
    padding-left: 0;
}
.list-before-square {
    & > li {
        &::before {
            content: "■";
        }
    }
}

/******************************************
* common-style
******************************************/
.btn {
    display: inline-block;
    background: none;
    padding: 0;
    border: none;
    text-decoration: none !important;
    cursor: pointer;
    font-weight: normal;
    @include hover-focus;
    &.btn-pink {
        background-color: #E8008A;
        color: white;
        font-size: 1.8rem;
        padding: 1.4rem 2.4rem;
        border-radius: 100px;
    }
    &.btn-simple-more{
        font-size: 1.4rem;
        font-weight: bold;
        color:#E8008A;
    }
}